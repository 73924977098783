<template>
  <div class="page" >
    <ls-title-header @back="cancelMain" title="机构信息" ></ls-title-header>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <div style="background-color: white;">
        <div class="box">
          <el-row class="row">
            <el-col :span="12">
                <el-form-item prop="name">
                  <template #label><span class="title">机构名称</span></template>
                  <span class="content" v-if="!editShow">{{unitData.name}}</span>
                  <el-input v-if="editShow" type="text" v-model="ruleForm.name" placeholder="请输入机构名称" maxlength="20" show-word-limit ></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item prop="login">
                  <template #label><span class="title">账&emsp;&emsp;号</span></template>
                  <span class="content" v-if="!editShow">{{unitData.login}}</span>
                  <el-input v-if="editShow" type="text" v-model="ruleForm.login" placeholder="请输入账号" maxlength="20" show-word-limit></el-input>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">联&ensp;系&ensp;人</span></template>
                  <span class="content" v-if="!editShow">{{unitData.linkMan}}</span>
                  <el-input v-if="editShow" type="text" v-model="ruleForm.linkMan" placeholder="请输入联系人" maxlength="10" show-word-limit></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12" v-show="!editShow">
                <el-form-item>
                  <template #label><span class="title">密&emsp;&emsp;码</span></template>
                  <el-button  type="warning" style="margin-left:5px;" size="mini" @click="psdVisible=true">修改密码</el-button>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">联系电话</span></template>
                  <span class="content" v-if="!editShow">{{unitData.linkPhone}}</span>
                  <el-input v-if="editShow" type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="ruleForm.linkPhone" placeholder="请输入联系电话" maxlength="15" show-word-limit></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">使用状态</span></template>
                  <span class="content" v-if="!editShow && this.unitData.useFlag==1">正常</span>
                  <span class="content" v-if="!editShow && this.unitData.useFlag==0">关闭</span>
                  <el-switch v-if="editShow" style="display: block;margin-top: 10px;width:150px;" v-model="ruleForm.useState" active-color="#13ce66" active-text="正常" inactive-text="关闭">
                  </el-switch>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">居住地址</span></template>
                  <span class="content" v-if="!editShow">{{unitData.desAddress}}</span>
                  <ls-address v-if="editShow" @adr-change="getAddress" :province="this.address.province" :city="this.address.city" :district="this.address.district" :street="this.address.street"></ls-address>
                  <el-input v-if="editShow" type="text" v-model="this.address.detail" placeholder="请输入详细地址" style="margin-top:20px;" maxlength="50" show-word-limit></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">服务时间</span></template>
                  <span class="content">{{unitData.sysSetData.beginTime}} 至 {{unitData.sysSetData.endTime}}</span>
                  <el-button v-if="editShow" type="warning" style="margin-left:10px;" size="mini"  @click="delayedTime" >延时</el-button>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item >
                  <template #label><span class="title">系统显示</span></template>
                  <span class="content" v-if="!editShow && unitData.sysSetData.showChangeSwitch == 1">可修改</span>
                  <span class="content" v-if="!editShow && unitData.sysSetData.showChangeSwitch == 0">不可修改</span>
                  <el-select v-if="editShow" v-model="ruleForm.sysSetData.showChangeSwitch"  placeholder="是否修改" style="width:100%">
                    <el-option v-for="item in sysShowOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>
          </el-row>

          <hr style="border-color: #f4f4f526;" />

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">电话授权数量</span></template>
                  <span class="content" >{{unitData.resDistributeData.voiceNum}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="8">
                <el-form-item>
                  <template #label><span class="title">电话使用数量</span></template>
                  <span class="content" >{{unitData.resReduceData.voiceNum}}</span>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item>
                  <template #label><span class="title">电话剩余数量</span></template>
                  <span class="content" >{{unitData.resDistributeData.voiceNum - unitData.resReduceData.voiceNum}}</span>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item>
                  <el-button v-if="!editShow" type="warning" style="float: left" size="small"  @click="empower(2)" >电话授权</el-button>
                </el-form-item>
            </el-col>

          </el-row>

          <!-- <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="">
                  <el-button v-if="editShow" type="warning" style="float: right" size="small"  @click="empower(1)" >电话授权</el-button>
                </el-form-item>
            </el-col>
          </el-row> -->

          <hr style="border-color: #f4f4f526;" />

          <el-row class="row">
            <el-col :span="12">
                <el-form-item>
                  <template #label><span class="title">短信授权数量</span></template>
                  <span class="content" >{{unitData.resDistributeData.smsNum}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">短信使用数量</span></template>
                <span class="content" >{{unitData.resReduceData.smsNum}}</span>
              </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item>
                  <template #label><span class="title">短信剩余数量</span></template>
                  <span class="content" >{{unitData.resDistributeData.smsNum - unitData.resReduceData.smsNum}}</span>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item>
                  <el-button v-if="!editShow" type="warning" style="float: left" size="small"  @click="empower(1)" >短信授权</el-button>
                </el-form-item>
            </el-col>
          </el-row>

          <!-- <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="">
                  <el-button v-if="editShow" type="warning" style="float: right" size="small"  @click="empower(2)" >短信授权</el-button>
                </el-form-item>
            </el-col>
          </el-row> -->

          <el-row class="row">
            <el-col :span="24">
              <div  style="display: flex; align-items: center">
                <!-- <el-button v-if="!editShow" type="primary" style="float: right" size="small" @click="cancelMain">返回</el-button> -->
                <el-button v-if="!editShow" type="primary" style="float: right" size="small" @click="edit">编辑</el-button>
                <el-button v-if="editShow" type="primary" style="float: right" size="small" @click="cancelEdit">返回</el-button>
                <el-button v-if="editShow" type="primary" style="float: right" size="small" @click="submit('ruleForm')">提交</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>

      <el-dialog v-model="delayedDialog"  width="30%" title="服务时间" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true" >

        <!-- <span class="title">延迟至</span> -->
        <div class="block">
          <el-date-picker style="width: 460px"  type="date"  format="YYYY 年 MM 月 DD 日"
                value-format="YYYY-MM-DD" v-model="endTime" placeholder="请输入延长日期"></el-date-picker>
        </div>

        <template #footer>
          <span class="dialog-footer">
              <el-button @click="delayedDialog = false">取 消</el-button>
              <el-button type="primary" @click="changeEndTime" >确 定</el-button>
          </span>
        </template>
    </el-dialog> 

    <el-dialog v-model="empowerDialog"  width="30%" title="授权" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="true" >
      <div class="block">
        <el-input v-model="empowerNumber" type="text" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="6" show-word-limit placeholder="请输入授权数量"></el-input>
      </div>

      <template #footer>
        <span class="dialog-footer">
            <el-button @click="cancelEmpower">取 消</el-button>
            <el-button type="primary" @click="changeEmpower">确 定</el-button>
        </span>
      </template>
    </el-dialog> 

    <el-dialog title="修改密码" v-model="psdVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="30%" >
      <div>
        <span>新密码：</span>
        <el-input v-model="password" placeholder="输入新密码" style="width: 80%" ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="psdVisible=false">取消</el-button>
          <el-button type="primary" @click="changePwd" size="small">确定</el-button>
        </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import { getCustomerInfo,updateCustomerInfo,empowerResource } from '../../api/api'
import session from '../../store/store'
import until from '../../until/until'
import crypto from '../../until/crypto'

export default {

  data() {
    return {
        psdVisible:false,
        editShow: false,
        delayedDialog:false,
        empowerDialog:false,
        endTime:"",
        empowerNumber:'',
        empowerType:0,
        ruleForm: {
          name:'',
          login:'',
          address:'',
          sysSetData:{
            endTime:'',
            showChangeSwitch:1,
          },
          sysDisplayData:{},
          resDistributeData:{
            voiceNum:0,
            smsNum:0,
          },
          resReduceData:{},
          infoData:{},
          entryDisplayData:{}
        },
        address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
        },
        uuid:'',
        password:'',
        unitData:{
          sysSetData:{},
          sysDisplayData:{},
          resDistributeData:{},
          resReduceData:{},
          infoData:{},
          entryDisplayData:{}
        },
        sysShowOption:[{
            value: 1,
            label: '可修改'
          },{
            value: 0,
            label: '不可修改'
          }
        ],
        rules: {
          name: [
            { required: false, message: '请输入机构名称', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          login: [
            { required: false, message: '请输入账号', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
        }
    }
  },
  mounted() {

  },
  methods: {

    getParentData(uuid) {
      this.uuid = uuid;
      this.getCustomerInfo();
    },
    getCustomerInfo() {
      var param = {
        uuid: this.uuid,
      }
      getCustomerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.unitData = res.data.dataInfo;
          this.unitData.desAddress = this.unitData.address.replaceAll("#","");

          let addressArr = this.unitData.address.split("#");
          if(addressArr.length == 5) {
            this.address.province = addressArr[0];
            this.address.city = addressArr[1];
            this.address.district = addressArr[2];
            this.address.street = addressArr[3];
            this.address.detail = addressArr[4];
          }

          this.ruleForm = this.unitData;
          if(this.ruleForm.useFlag == 1) {
            this.ruleForm.useState = true;
          }else {
            this.ruleForm.useState = false;
          }
        } 
      })
    },

    changePwd() {
      if (until.isNULL(this.password)) {
        this.$message({ message: '请输入密码',  type: 'warning'});
        return;
      }
      var param = {
        uuid: this.uuid,
        parentUnitUuid:session.getLoginInfo().unitUuid,
        password:this.$md5(this.password),
      }
      updateCustomerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.psdVisible = false;
          this.$message({ message: '修改成功',  type: 'success'});
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
      
    },

    //延时
    delayedTime() {
      this.delayedDialog = true;
    },
    changeEndTime() {
      this.delayedDialog = false;
      this.ruleForm.sysSetData.endTime = this.endTime;
      this.ruleForm.endTime = this.endTime;
    },
    //授权
    empower(type) {
      this.empowerType = type;
      this.empowerDialog = true;
    },
    cancelEmpower() {
      this.empowerNumber = '';
      this.empowerDialog = false;
    },
    changeEmpower() {
      if(until.isNULL(this.empowerNumber)) {
        this.$message.error({ message: "不能为空"});
        return
      }
      this.empowerDialog = false;
      if(this.empowerType == 1) {
        this.ruleForm.resDistributeData.smsNum = this.empowerNumber;
      }else if(this.empowerType == 2) {
        this.ruleForm.resDistributeData.voiceNum = this.empowerNumber;
      }
      this.empowerNumber = '';

      
      var param = {
        uuid: this.uuid,
        empowerResource: crypto.Encrypt(JSON.stringify(this.ruleForm.resDistributeData)),
        empowerType: this.empowerType
      }

      empowerResource(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '授权成功',  type: 'success'});
          this.getCustomerInfo();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })

    },

    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },

    submit(formName) {
      
       this.$refs[formName].validate((valid) => {
          if (valid) {
            this.update();
          } else {
            return false;
          }
        });
    },

    update() {
      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.ruleForm.address = '';
      }else {
        this.ruleForm.address = address;
      }

      if(this.ruleForm.useState) {
        this.ruleForm.useFlag = 1;
        this.ruleForm.sysSetData.useFlag = 1;
      }else {
        this.ruleForm.useFlag = 0;
        this.ruleForm.sysSetData.useFlag = 0;
      }

      
      var param = {
        uuid: this.uuid,
        parentUnitUuid:session.getLoginInfo().unitUuid,
        name:this.ruleForm.name,
        login:this.ruleForm.login,
        address:this.ruleForm.address,
        linkMan:this.ruleForm.linkMan,
        linkPhone:this.ruleForm.linkPhone,
        useFlag:this.ruleForm.useFlag,
        sysSet:crypto.Encrypt(JSON.stringify(this.ruleForm.sysSetData)),
        // resDistribute:crypto.Encrypt(JSON.stringify(this.ruleForm.resDistributeData))
      };

      updateCustomerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.cancelEdit();
          this.getCustomerInfo();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    cancelMain() {
      this.$emit("cancel");
    },
    edit() {
      this.editShow = true;
      this.rules.name[0].required = true;
      this.rules.login[0].required = true;
    },
    cancelEdit() {
      this.editShow = false;
      this.rules.name[0].required = false;
      this.rules.login[0].required = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -15px;
}
.row {
  margin: 20px 0px 20px 0px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.title {
  margin-right: 10px;
  font-size: 16px;
  color: #8D8D8D;
}

.content {
  font-size: 16px;
  margin-left: 8px;
}


</style>



<style lang="scss">

// .input-class {
//   .el-input__inner{
//     width: 460px; 
//   } 
// }
</style>
