<template>
   <div class="page" >
    <ls-title-header @back="cancel" title="新增机构" ></ls-title-header>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
    <div style="background-color: white;">
      <div class="box">
        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="name">
                <template #label><span class="title">机构名称</span></template>
                <el-input type="text" v-model="ruleForm.name" placeholder="请输入机构名称" maxlength="20" show-word-limit ></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="linkMan">
                <template #label><span class="title">联&ensp;系&ensp;人</span></template>
                <el-input type="text" v-model="ruleForm.linkMan" placeholder="请输入联系人" maxlength="10" show-word-limit></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="linkPhone">
                <template #label><span class="title">联系电话</span></template>
                <el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="ruleForm.linkPhone" placeholder="请输入联系电话" maxlength="15" show-word-limit></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="address">
                <template #label><span class="title">居住地址</span></template>
                <ls-address @adr-change="getAddress" style="display: flex;justify-content: space-between;width:100%;"></ls-address>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
               <el-form-item prop="detail">
                <template #label><span class="title"></span></template>
                <el-input type="text" v-model="this.address.detail" placeholder="请输入详细地址" maxlength="50" show-word-limit></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
               <el-form-item >
                <template #label><span class="title">系统显示</span></template>
                <el-select v-model="ruleForm.sysSet.showChangeSwitch"  placeholder="是否修改" style="width:100%">
                  <el-option v-for="item in sysShowOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
               <el-form-item prop="endTime">
                <template #label><span class="title">到期时间</span></template>
                <el-date-picker style="width: 100%"  type="date" format="YYYY 年 MM 月 DD 日"
                value-format="YYYY-MM-DD" v-model="ruleForm.endTime" placeholder="到期时间"></el-date-picker>
              </el-form-item>
          </el-col>
        </el-row>

        <hr style="border-color: #f4f4f526;" />

        <el-row class="row">
          <el-col :span="24">
               <el-form-item prop="login">
                <template #label><span class="title">账&emsp;&emsp;号</span></template>
                <el-input type="text" v-model="ruleForm.login" placeholder="请输入账号" maxlength="20" show-word-limit></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
               <el-form-item prop="password">
                <template #label><span class="title">默认密码</span></template>
                <el-input type="text" v-model="ruleForm.password" :disabled="true"></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
            <el-form-item>
              <!-- <el-button type="primary" style="float: right" size="small" @click="cancel">返回</el-button> -->
              <el-button type="primary" size="small" @click="submit('ruleForm')">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {addUnitInfo } from '../../api/api'
import session from '../../store/store'
import crypto from '../../until/crypto'
// import until from '../../until/until'
export default {

  data() {
    return {
        ruleForm: {
          name: '',
          linkMan:'',
          linkPhone:'',
          address:'',
          login:'',
          password:'123456',
          endTime:'',
          sysSet:{
            endTime:'',
            showChangeSwitch:1,
          }
        },
        address: {
          province:'',
          city:'',
          district:'',
          street:'',
          detail:'',
        },
        sysShowOption:[{
            value: 1,
            label: '可修改'
          },{
            value: 0,
            label: '不可修改'
          }
        ],
        rules: {
          name: [
            { required: true, message: '请输入机构名称', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          login: [
            { required: true, message: '请输入账号', trigger: 'blur' },
            { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
          ],
          endTime: [
            { required: true, message: '请输入到期时间', trigger: 'change' }
          ],
        }
    }
  },
  methods: {
    
    getAddress(data){
      this.address.province = data.selectProvince;
      this.address.city = data.selectCity;
      this.address.district =  data.selectDistrict;
      this.address.street = data.selectStreet
    },

    submit(formName) {
       this.$refs[formName].validate((valid) => {
          if (valid) {
            this.addUnit();
          } else {
            return false;
          }
        });
    },
    addUnit() {
      let address = this.address.province + '#' + this.address.city + '#' + this.address.district + '#' + this.address.street + '#' + this.address.detail;

      if(address == '####') {
        this.ruleForm.address = '';
      }else {
        this.ruleForm.address = address;
      }
      this.ruleForm.sysSet.endTime = this.ruleForm.endTime;
      var param = {
        parentUnitUuid:session.getLoginInfo().unitUuid,
        name:this.ruleForm.name,
        login:this.ruleForm.login,
        password:this.$md5(this.ruleForm.password),
        address:this.ruleForm.address,
        sysSet:crypto.Encrypt(JSON.stringify(this.ruleForm.sysSet)),
        linkMan:this.ruleForm.linkMan,
        linkPhone:this.ruleForm.linkPhone,
        // endTime: this.ruleForm.endTime
      }
      addUnitInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '新增成功',  type: 'success'});
          this.close();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })

    },
    cancel() {
      this.$emit("cancel");
    },
    close() {
      this.$emit('update')
    },

  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -20px;
}

.row {
  margin: 20px 0px 20px 0px;
}
.box {
  padding: 20px;
  width: 75%;
  background-color: white;
}
.title {
  margin-right: 25px;
  font-size: 16px;
}

</style>
