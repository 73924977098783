<template>
  <div class="page" v-if="mainPage">
    <!--标题-->
    <ls-title title="B级机构列表显示"></ls-title>
    <!-- 条件框 -->
    <div class="conddiv">
      <span></span>
      <el-input v-model="condition" class="w-50 m-2" placeholder="机构名称" prefix-icon="el-icon-search"  style="width:200px;" clearable>
      </el-input>
      <el-button type="warning" style="margin-left:5px;" @click="search">搜索</el-button>
      <el-button type="primary" style="float:right;" @click="addUnitFunc" >新增机构</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border style="width: 100%;border-radius: 10px;" :header-cell-style="{ background: '#285df0'}" :empty-text="emptyText">
      <el-table-column type="index" label="序号"  width="50" label-class-name="tablehead" align="center " />
      <el-table-column prop="unitName" label="机构名称"  label-class-name="tablehead" align="center " />
      <el-table-column prop="useState" label="使用状态" label-class-name="tablehead" align="center " >
        <template #default="scope">
          <span v-if="scope.row.useState == 1">正常</span>
          <span v-if="scope.row.useState == 0">关闭</span>
        </template>
      </el-table-column>
      <el-table-column prop="beginTime" label="开通时间" label-class-name="tablehead" align="center " />
      <el-table-column prop="endTime" label="到期时间" label-class-name="tablehead" align="center " />
      <el-table-column prop="linkMan" label="联系人" label-class-name="tablehead" align="center " />
      <el-table-column prop="linkPhone" label="联系电话" label-class-name="tablehead" align="center " />
      <el-table-column label="操作" label-class-name="tablehead" align="center " width="100">
          <template #default="scope">
            <span class="text-btn" @click="detail(scope.row)">管理</span> /
            <span class="text-btn" @click="deleteUnit(scope.row)">删除</span>
          </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination">
      <ls-pagination :currentPage="currentPage" :page-size="size" :total="total" @size-change="handleSizeChange" @cur-change="handleCurrentChange"></ls-pagination>
    </div>
  </div>

  <template v-if="addPage">
    <UnitAdd @cancel="cancelAddUnitPage" @update="update"></UnitAdd>
  </template>

  <template v-if="detailsPage">
    <UnitDetails ref="childView" @cancel="cancelUnitDetailsPage" ></UnitDetails>
  </template>
</template>

<script>
import {getUnitInfos,deleteUnitInfo } from '../../api/api'
import UnitAdd from './UnitAdd.vue'
import UnitDetails from './UnitDetails.vue'
import session from '../../store/store'

export default {
  components: {
    UnitAdd,
    UnitDetails
  },
  data() {
    return {
      emptyText:'加载中',
      condition: "",//条件
      currentPage: 1,
      size: 10,
      total: 0,
      mainPage: true,
      addPage: false,
      detailsPage: false,
      tableData: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {

    init() {
      this.getUnitInfos();
    },
    update() {
      this.cancelAddUnitPage();
      this.getUnitInfos();
    },
    search() {
      this.getUnitInfos();
    },
    getUnitInfos() {
      var param = {
        parentUuid:session.getLoginInfo().unitUuid,
        page:this.currentPage - 1,
        size:this.size,
        condition:this.condition,
      }
      getUnitInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.tableData = res.data.dataInfo;
        } else {
          this.total = 0;
          this.tableData.splice(0, this.tableData.length);
          this.emptyText = "暂无数据"
        }
      })
    },

    //新增机构界面
    addUnitFunc() {
      this.mainPage = false;
      this.addPage = true;
    },

    cancelAddUnitPage() {
      this.mainPage = true;
      this.addPage = false;
    },

    detail(row) {
      this.mainPage = false;
      this.detailsPage = true;
      setTimeout(() => {
        this.$refs.childView.getParentData(row.uuid);
      }, 200);
    },

    deleteUnit(row) {
      this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var param = {
            parentUuid:session.getLoginInfo().unitUuid,
            uuid:row.uuid,
          }
          deleteUnitInfo(param).then(res => {
            if (res.data.code == 200) {
              this.$message({ message: '删除成功',  type: 'success'});
              this.getUnitInfos();
            } else {
              this.$message.error({ message: res.data.message});
            }
          })

        }).catch(() => {});
    },
    cancelUnitDetailsPage() {
      this.mainPage = true;
      this.detailsPage = false;
    },
      //分页
    handleSizeChange(val) {
      this.size = val;
      this.getUnitInfos();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUnitInfos();
    },

  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 0px 20px;
}
.conddiv {
  background-color: white;
  border-radius: 10px;
  padding: 15px 20px;
  box-shadow: 0px 2px 3px 4px var(--el-color-info-light);
  margin-bottom: 20px;
}
.pagination {
  background-color: white;
  text-align: center;
  padding: 20px 0px;
  margin-top: 20px;
  border-radius: 10px;
}
.text-btn {
  color: #285df0;
  cursor: pointer;
}
</style>
<style>
/* .el-input__prefix {
  top: 5px !important;
}*/
.tablehead {
  color: white;
} 
</style>